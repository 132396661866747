import React from "react"

const GatsbyHeader = ({ size, style, children }) => {
  const headerStyle = Object.assign({
    flex: '1 100%',
    padding: '' + (3 * size) + 'px',
    fontSize: '' + (10 * size) + 'px',
    background: '#F59332',
    color: 'white',
  }, style)

  return (
    <div style={headerStyle}>{children}</div>
  )
}

export default GatsbyHeader
